import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />

      <div className="flex flex-wrap mt-2 bg-white border border-gray-300">
        <div className="w-full lg:w-3/5 p-4 border-r border-gray-300 pt-10 md:p-10">
          <h1 className={`pb-4 border-b border-gray-300 mb-8`}>Contact Us</h1>

          <p>
            If you are passionate about running your own pub business, please
            get in touch about what we can offer.
          </p>

          <h3>Shaun Jackson - Midlands, North & Scotland</h3>
          <ul>
            <li>
              <a href="mailto:shaun.jackson@mbplc.com">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`mr-2 inline-block`}
                >
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"></path>
                </svg>
                shaun.jackson@mbplc.com
              </a>
            </li>
            <li>
              <a href="tel:07808 094463">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`mr-2 inline-block`}
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  ></path>
                </svg>
                07743 972838
              </a>
            </li>
          </ul>

          <h3>Richard Flaxman - South and South Walesd</h3>
          <ul>
            <li>
              <a href="mailto:richard.flaxman@mbplc.com">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`mr-2 inline-block`}
                >
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"></path>
                </svg>
                richard.flaxman@mbplc.com
              </a>
            </li>
            <li>
              <a href="tel:07808 095001">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`mr-2 inline-block`}
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  ></path>
                </svg>
                07808 095001
              </a>
            </li>
          </ul>

          <h3>Or write to:</h3>
          <p>
            Mitchells & Butlers Lease
            <br />
            27 Fleet Street
            <br />
            Birmingham
            <br />
            B3 1JP
          </p>
        </div>

        <div className="w-full lg:w-2/5 p-4 md:p-10">
          <StaticImage
            src="../../static/images/mab_building.jpg"
            alt="Mitchells & Butlers Building"
          />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
